<template>
  <div>
    <button class="loginButton" @click="handleLogin">
      <slot></slot>
    </button>
  </div>
</template>
<script>
export default {
  name: 'LoginButton',
  components: {},
  props: {},
  data() {
    return {}
  },
  methods: {
    handleLogin() {
      this.$Login()
    }
  },
  created() {}
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
