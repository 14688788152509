/**
 * 图片压缩页面配置
 */
export const IMG_COMPRESS_PAGE_CONFIG = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '批量处理',
      content: '批量进行图片格式转换，支持多种图片格式互转，满足您的转换需求。',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区或点击添加按钮，将文件上传至平台，稍等片刻即可快速转换。',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: '云端引擎',
      content: '基于浏览器运行，整个过程依赖服务器端进行，无需额外下载安装客户端软件。',
      icon: require('../assets/img/cloud_engine.png')
    }
  ],
  STEP_TITLE: '图片压缩三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '根据预期需求设置图片修改的参数，点击“开始压缩”',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载全部”按钮即可保存压缩好的图片',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_3.png'
    }
  ]
}

/**
 * 图片编辑页面配置
 */
export const IMG_EDIT_PAGE_CONFIG = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '批量处理',
      content: '批量进行图片格式转换，支持多种图片格式互转，满足您的转换需求。',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区或点击添加按钮，将文件上传至平台，稍等片刻即可快速转换。',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: '云端引擎',
      content: '基于浏览器运行，整个过程依赖服务器端进行，无需额外下载安装客户端软件。',
      icon: require('../assets/img/cloud_engine.png')
    }
  ],
  STEP_TITLE: '图片压缩三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '根据预期需求设置图片修改的参数，点击“开始压缩”',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载全部”按钮即可保存压缩好的图片',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_3.png'
    }
  ]
}
/**
 * 图片去除水印页面配置
 */
export const IMG_EDIT_REMOVE_WATERMARK_PAGE_CONFIG = {
  FEATURES_TITLE: '',
  FEATURES: [],
  STEP_TITLE: '图片压缩三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '根据预期需求设置图片修改的参数，点击“开始压缩”',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载全部”按钮即可保存压缩好的图片',
      src: 'https://res.yunkun.cn/img_geshicn/img/compress_step_3.png'
    }
  ]
}
/**
 * 图片格式转换页面配置
 */
export const IMG_CONVERT_PAGE_CONFIG = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '批量处理',
      content: '批量进行图片格式转换，支持多种图片格式互转，满足您的转换需求。',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区或点击添加按钮，将文件上传至平台，稍等片刻即可快速转换。',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: '云端引擎',
      content: '基于浏览器运行，整个过程依赖服务器端进行，无需额外下载安装客户端软件。',
      icon: require('../assets/img/cloud_engine.png')
    }
  ],
  STEP_TITLE: '图片转换三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: require('@/assets/img/compress_step_1.png')
    },
    {
      title: '选择转换格式',
      content: '设置图片转换后的目标格式，点击“立即转换”按钮',
      src: require('@/assets/img/convert_step_2.png')
    },
    {
      title: '下载图片',
      content: '点击“下载全部”按钮即可保存转换好的图片',
      src: require('@/assets/img/convert_step_3.png')
    }
  ]
}

/**
 * PDF转换页面配置
 */
export const PDF_CONVERT_PAGE_CONFIG = {
  FEATURES_TITLE: 'PDF转换功能优势',
  FEATURES: [
    {
      title: '一键转换文件格式',
      content: '无需繁琐操作，上传即可完成文件格式转换',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: '无限次使用',
      content: '支持无限次使用PDF处理工具，在线轻松完成文档处理',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '支持多种格式互转',
      content: '支持对PDF、Word、Excel、PPT、图片等文件格式互转',
      icon: require('../assets/img/feature_covert_icon.png')
    }
  ],
  STEP_TITLE: 'PDF转换三步完成',
  STEPS: [
    {
      title: '上传文件',
      content: '在文件添加区域选择您的文件上传',
      src: require('@/assets/img/pdf_convert_step_1.png')
    },
    {
      title: '转换完成',
      content: '上传即可完成文件格式转换',
      src: require('@/assets/img/pdf_convert_step_2.png')
    },
    {
      title: '下载文件',
      content: '点击“立即下载”按钮即可保存转换好的文件',
      src: require('@/assets/img/pdf_convert_step_3.png')
    }
  ]
}

/**
 * 图片老照片页面配置
 */
export const LAOZHAOPIAN = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '多种格式',
      content: '支持JPG、JPEG、PNG、WEBP、BMP、TIFF图片格式 ',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区，设置参数，点击开始按钮，将文件上传至平台，稍等片刻即可快速处理完成',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: 'AI技术',
      content: 'AI智能图像修复技术，修复模糊照片、低清照片、斑驳痕迹；黑白照上色，精准还原场景色彩，赋予旧照片新生。',
      icon: require('../assets/img/PropertyAI.svg')
    }
  ],
  STEP_TITLE: 'AI无损放大图片三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '设置放大倍数参数，点击“开始放大”',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载”或右上角“下载全部”按钮即可保存放大好的图片',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_3.png'
    }
  ]
}

/**
 * 图片放大页面配置
 */
export const IMG_EDIT_PAGE_CONFIG_AMPLIFICATION = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '在线处理',
      content: '在线无损放大图像，尺寸放大2倍、4倍、6倍、8倍，自动修复图片质量，帮助您获得清晰大图效果。',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区点击开始放大按钮，设置放大倍数，将文件上传至平台，稍等片刻即可快速处理完成。',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: 'AI放大',
      content: '简单快速在线放大图片尺寸，2-8倍超分智能放大，自动修复图片质量，提升图片整体清晰度。',
      icon: require('../assets/img/PropertyAI.svg')
    }
  ],
  STEP_TITLE: 'AI无损放大图片三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '设置放大倍数参数，点击“开始放大”',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载”或右上角“下载全部”按钮即可保存放大好的图片',
      src: 'https://res.yunkun.cn/pic_geshicn/fangda_step_3.png'
    }
  ]
}
/**
 * 图片清晰度增强页面配置
 */
export const IMG_EDIT_PAGE_CONFIG_CLEARNESS = {
  FEATURES_TITLE: '为什么应该选择「轻秒图片转换器」？',
  FEATURES: [
    {
      title: '安全可靠',
      content: '我们承诺在处理文件后的30分钟后自动将文件从网站的服务器永久删除，100%保障你的隐私。',
      icon: require('../assets/img/safe_and_reliable.png')
    },
    {
      title: '在线处理',
      content: '在线AI修复图片清晰度，一键修复模糊照片，优化图像纹理细节，智能快速去噪，并将其无损放大。',
      icon: require('../assets/img/batch_processing.png')
    },
    {
      title: '快速高效',
      content: '图片文件拖拽至转换区点击开始处理按钮，设置清晰倍数，将文件上传至平台，稍等片刻即可快速修复完成。',
      icon: require('../assets/img/fast_and_efficient.png')
    },
    {
      title: 'AI增强',
      content: '智能增强修复模糊图片，根据图片模糊程度进行自适应锐化增强，提升图片整体清晰度。',
      icon: require('../assets/img/PropertyAI.svg')
    }
  ],
  STEP_TITLE: 'AI图片变清晰三步完成',
  STEPS: [
    {
      title: '上传图片',
      content: '在图片添加区域选择您的图片文件上传',
      src: 'https://res.yunkun.cn/pic_geshicn/hd_step_1.png'
    },
    {
      title: '选择参数设置',
      content: '设置图片增强参数，点击“开始增强”',
      src: 'https://res.yunkun.cn/pic_geshicn/hd_step_2.png'
    },
    {
      title: '下载图片',
      content: '点击“下载”或右上角“下载全部”按钮即可保存修复好的图片',
      src: 'https://res.yunkun.cn/pic_geshicn/hd_step_3.png'
    }
  ]
}
