import { loadImage } from '@/utils'

export const mergeImage = (imgList, options) =>
  new Promise(async resolve => {
    let task = imgList.map(src => loadImage(src))
    let results = await Promise.all(task)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    ctx.imageSmoothingEnabled = false
    canvas.width = options.width
    canvas.height = options.height

    results.forEach(img => {
      const scale = Math.min(options.width / img.width, options.height / img.height)
      const newWidth = img.width * scale
      const newHeight = img.height * scale
      const offsetX = (options.width - newWidth) / 2
      const offsetY = (options.height - newHeight) / 2
      ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight)
    })
    canvas.toBlob(
      blob => {
        resolve(blob)
      },
      options.type || 'image/png',
      options.quality || 1
    )
  })
