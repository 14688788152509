<template>
  <el-dialog
    class="messageDialogBox"
    :visible.sync="visible"
    width="400px"
    :append-to-body="true"
    :show-close="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'messageDialogContainer'"
    @open="onDialogOpen"
    :destroy-on-close="true"
  >
    <div class="bindDialog">
      <div class="titleBox">
        <span class="title">绑定微信</span>
        <i class="el-icon-close closeIcon" @click="handleClose"></i>
      </div>
      <div class="content">
        <div class="qrCodeWrapper">
          <div class="loadingBox" v-if="isLoading">
            <!--            <img src="@/assets/img/qr_code_loading.jpg" alt="" />-->
          </div>
          <div class="qrcodeContentBox" v-else>
            <img v-if="qrCodeStatus === QR_CODE_STATUS.SUCCESS" :src="loginQrCodeUrl" alt="" style="width: 100%" />
            <div class="qrCodeStatusBox" v-else>
              <div class="qrCodeStatusContent">
                <span class="refreshBtn" @click="onRefreshQrCode"></span>
                <span class="txt">{{ qrCodeStatus === QR_CODE_STATUS.EXPIRE ? '二维码已过期' : '获取失败' }}</span>
              </div>
            </div>
          </div>
        </div>
        <p class="tips">请使用微信扫描二维码登录</p>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { bindWechat, getLoginQrCode } from '@/api'
import { Message } from 'element-ui'

const QR_CODE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EXPIRE: 'expire'
}
export default {
  name: 'BindWechat',
  data() {
    return {
      title: '温馨提示',
      visible: false,
      onClose() {},
      afterClose() {},
      onBindSuccess() {},
      onBindFail() {},
      timer: null,
      isCountDown: false,
      seconds: 60,
      QR_CODE_STATUS,
      qrCodeStatus: QR_CODE_STATUS.PENDING,
      loginQrCodeUrl: '',
      isLoading: false,
      checkLoginStatusTimer: null
    }
  },
  methods: {
    hide() {
      this.visible = false
      if (typeof this.afterClose === 'function') {
        this.afterClose(this)
      }
    },
    handleClose() {
      this.hide()
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },
    loopCheckLoginStatus(ticket, expire_seconds, startTime) {
      clearTimeout(this.checkLoginStatusTimer)
      let currentTime = new Date().getTime()
      if ((currentTime - startTime) / 1000 > expire_seconds) {
        // TODO: 二维码已过期
        this.qrCodeStatus = QR_CODE_STATUS.EXPIRE
        return
      }
      this.checkLoginStatusTimer = setTimeout(async () => {
        let r = await bindWechat(ticket)
        if (r.data.status === 0) {
          this.onBindSuccess()
          clearTimeout(this.checkLoginStatusTimer)
        } else if (r.data.status === 1009) {
          this.onBindFail(r.data)
          clearTimeout(this.checkLoginStatusTimer)
          await this.handleGetLoginQrCode() // 重新获取二维码
        } else {
          this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
        }
      }, 1500)
    },
    async handleGetLoginQrCode() {
      this.isLoading = true
      try {
        let res = await getLoginQrCode()
        if (res.data.status === 0) {
          let { ticket, expire_seconds } = res.data.data
          if (ticket) {
            let src = `https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=${ticket}`
            let startTime = new Date().getTime()
            this.qrCodeStatus = QR_CODE_STATUS.SUCCESS
            this.loginQrCodeUrl = src
            this.loopCheckLoginStatus(ticket, expire_seconds, startTime)
          } else {
            // 获取二维码失败
            this.qrCodeStatus = QR_CODE_STATUS.FAIL
          }
        } else {
          // 获取二维码失败
          this.qrCodeStatus = QR_CODE_STATUS.FAIL
        }
      } catch (e) {
        this.qrCodeStatus = QR_CODE_STATUS.FAIL
      } finally {
        this.isLoading = false
      }
    },
    async onRefreshQrCode() {
      await this.handleGetLoginQrCode()
    },
    // 当弹窗打开的时候调用
    async onDialogOpen() {
      await this.handleGetLoginQrCode()
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
