<template>
  <el-dialog
    class="loginBox"
    :visible.sync="visible"
    width="320px"
    :append-to-body="true"
    :show-close="false"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'loginContainer'"
    @open="onDialogOpen"
    @closed="handleClose"
    :destroy-on-close="true"
  >
    <div class="loginMain">
      <i class="el-icon-close closeIcon" @click="handleClickClose"></i>
      <div class="mainRight">
        <div class="title">手机上传图像</div>
        <div class="tips">支持20M以下的图片</div>
        <div class="qrCodeWrapper">
          <div class="loadingBox" v-if="isLoading">
            <!--            <img src="@/assets/img/qr_code_loading.jpg" alt="" />-->
          </div>
          <div class="qrcodeContentBox" v-else>
            <img v-if="qrCodeStatus === QR_CODE_STATUS.SUCCESS" :src="loginQrCodeUrl" alt="" style="width: 100%" />
            <div class="qrCodeStatusBox" v-else>
              <div class="qrCodeStatusContent">
                <span class="refreshBtn" @click="onRefreshQrCode"></span>
                <span class="txt">{{ qrCodeStatus === QR_CODE_STATUS.EXPIRE ? '二维码已过期' : '获取失败' }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tips">扫一扫二维码上传</div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getOssDirFileList } from '@/utils/uploadOSS'
import QRCode from 'qrcode'
import { v4 as uuidV4 } from 'uuid'
import { BUCKET } from '@/store/amplification.store'
import { getToken } from '@/utils/token'
const QR_CODE_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  EXPIRE: 'expire'
}
export default {
  name: 'UploadModal',
  data() {
    return {
      QR_CODE_STATUS,
      qrCodeStatus: QR_CODE_STATUS.PENDING,
      loginQrCodeUrl: '',
      isLoading: false,
      checkUploadStatusTimer: null,
      visible: false,
      afterClose() {},
      onCancel() {},
      onSuccess() {},
      config: {
        accept: 'image/*'
      },
      timer: null
    }
  },
  computed: {},
  methods: {
    handleClickClose() {
      this.onCancel()
      this.hide()
    },
    handleClose() {
      this.afterClose()
      clearTimeout(this.checkUploadStatusTimer)
    },
    hide() {
      this.visible = false
    },
    async checkUploadStatus(ticket) {
      clearTimeout(this.checkUploadStatusTimer)
      this.checkUploadStatusTimer = setTimeout(async () => {
        let result = await getOssDirFileList(`h5/${ticket}/`, BUCKET)
        if (result.objects.length) {
          if (typeof this.onSuccess === 'function') {
            this.onSuccess(result.objects)
          }
          this.handleClose()
          this.hide()
        } else {
          await this.checkUploadStatus(ticket)
        }
      }, 1500)
    },
    async handleGetUploadQrCode() {
      if (!getToken()) {
        let that = this
        this.$Login({
          onSuccess() {
            that.handleGetUploadQrCode()
          }
        })
        return
      }
      this.isLoading = true
      try {
        const ticket = uuidV4()
        const uploadLink = `${'https://img.geshi.cn'}/mobile-upload?ticket=${encodeURIComponent(
          ticket
        )}&accept=${encodeURIComponent(this.config.accept)}`

        let qrUrl = await QRCode.toDataURL(uploadLink)
        // 二维码获取成功
        this.qrCodeStatus = QR_CODE_STATUS.SUCCESS
        this.loginQrCodeUrl = qrUrl
        await this.checkUploadStatus(ticket)
      } catch (e) {
        this.qrCodeStatus = QR_CODE_STATUS.FAIL
      } finally {
        this.isLoading = false
      }
    },
    async onRefreshQrCode() {
      await this.handleGetUploadQrCode()
    },
    // 当弹窗打开的时候调用
    async onDialogOpen() {
      await this.handleGetUploadQrCode()
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
