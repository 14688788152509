import authAxios from '@/axios/authAxios'

/**
 * @description 照片修复 模型二
 * @param file_addr 图片地址
 * @param {Number} type  0 : 通用 1:人像
 * @param molti 倍数
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function photoRestoration(file_addr, type, molti) {
  return authAxios.post(
    '/imageformatV2/imageclear',
    {
      file_addr: file_addr,
      type: type,
      molti: molti
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

/**
 * 照片修复 模型一
 * @param file_addr
 * @param type
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function photoRestoration2(file_addr, type) {
  return authAxios.post(
    '/imageformatV2/imageclearhs',
    {
      file_addr: file_addr,
      type: type
    },
    {
      headers: {
        ContentType: 'application/json'
      }
    }
  )
}

/**
 * @description 照片上色
 * @param file_addr 图片地址
 * @param {Number} format 图片格式 0:jpeg/jpg 1:png 2:webp
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function pictureDiscoloration(file_addr, format) {
  return authAxios.post(
    '/imageformatV2/colorization',
    {
      file_addr: file_addr,
      format: format
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

/**
 * @description 图片增强
 * @param source 文件OSS地址
 * @param molti 增强倍数 2,4,6,8
 * @returns {Promise<AxiosResponse<any>>}
 */
export function imageEnhance(source, molti) {
  return authAxios.post(
    // '/imageformat/enhancement',
    '/imageformatV2/enhancement',

    {
      file_addr: source,
      model: 0,
      molti: molti
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}


