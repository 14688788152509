import axios from '@/axios'
import authAxios from '@/axios/authAxios'
import { SOFT_CHANNEL, SOFT_CODE, SOFT_CODE_360 } from '@/config'
import { is360 } from '@/utils'

/**
 * @description 登录（已废弃）
 * @param code
 * @returns {Promise<AxiosResponse<any>>}
 */
export function userLogin(code) {
  return axios.get('/api/wxlogin?code=' + code)
}

/**
 * @description 扣除用户下载次数
 * @param count 要扣除的次数
 * @returns {Promise<AxiosResponse<any>>}
 */
export function duce(count = 1) {
  return authAxios.post(
    '/imageformatV2/duce',
    {
      c: count
    },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
}

/**
 * 获取用户信息
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getUserInfo() {
  // 旧接口：/imageformat/getuserinfo
  return authAxios.post('/imageformatV2/getuserinfo')
}

/**
 * 获取登录二维码
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLoginQrCode() {
  return axios.post('/api/wxgetqrcode')
}

/**
 * 检查微信登录状态
 * @param union_str
 * @returns {Promise<AxiosResponse<any>>}
 */
export function getLoginStatus(union_str) {
  const code = is360() && SOFT_CODE_360 ? SOFT_CODE_360 : SOFT_CODE
  return axios.post('/apiv2/wechatcheck', {
    code: window?.getAppRuntime?.platform === 'electron' ? window?.getAppRuntime?.softCode : code,
    soft_channel: SOFT_CHANNEL,
    union_str: union_str
  })
}

/**
 * 手机号码登录
 * @param code 软件code
 * @param soft_channel 渠道
 * @param mobile_phone 手机号
 * @param smscode 验证码
 * @returns {Promise<AxiosResponse<any>>}
 */
export const checkPhoneNumberLoginStatus = ({ code, soft_channel, mobile_phone, smscode }) => {
  return axios.post('/apiv2/smslogin', {
    code: code,
    soft_channel: soft_channel,
    mobile_phone: mobile_phone,
    smscode: smscode
  })
}
/**
 * 获取手机验证码
 * @param mobile_phone 手机号
 */
export const getMobileCode = mobile_phone => {
  const code = is360() && SOFT_CODE_360 ? SOFT_CODE_360 : SOFT_CODE
  return axios.post('/api/code', {
    mobile_phone,
    // code: 'image_convert_h5'
    code: window?.getAppRuntime?.platform === 'electron' ? window?.getAppRuntime?.softCode : code
  })
}

/**
 * 游客登录
 * @param code 软件code
 * @param soft_channel 来源
 */
export const visitorLogin = (code, soft_channel) => {
  return axios.post('/apiv2/guestlogin', {
    code: code,
    soft_channel: soft_channel
  })
}
