import { v4 as uuidV4 } from 'uuid'
import isEmpty from 'lodash-es/isEmpty'
import { getExtByMimeType, getMimeTypeByExt } from '@/utils'
import { duce, METHODS, imageEnhance } from '@/api'
import { uploadOSS } from '@/utils/uploadOSS'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { Message } from 'element-ui'
import axios from 'axios'
// 转换状态
export const CONVERT_STATUS = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail'
}

export const BUCKET = 'pdfgeshi'

let initState = {
  inputFile: {},
  outputFile: {},
  downloadHistory: {},
  downloadStatus: false, // 是否下载成功
  isDownloading: false, // 是否下载中
  convertStatus: CONVERT_STATUS.PENDING // 转换成功
}
export default {
  methods: {},
  namespaced: true,
  state: () => initState,
  mutations: {
    updateDownloadStatus: (state, status) => {
      state.downloadStatus = status
    },
    updateIsDownloading: (state, status) => {
      state.isDownloading = status
    },
    updateInputFile: (state, fileInfo) => {
      state.inputFile = fileInfo
    },
    updateOutputFile: (state, fileInfo) => {
      state.outputFile = fileInfo
    },
    updateConvertStatus: (state, status) => {
      state.convertStatus = status
    },
    updateDownloadHistory: (state, fileInfo) => {
      state.downloadHistory = fileInfo
    },
    resetStore: state => {
      state.convertStatus = CONVERT_STATUS.PENDING
      state.inputFile = {}
      state.outputFile = {}
      state.downloadHistory = {}
      state.downloadStatus = false // 是否下载成功
      state.isDownloading = false // 是否下载中
      state.convertStatus = CONVERT_STATUS.PENDING // 转换成功
    }
  },
  actions: {
    resetStore: ({ commit }) => {
      commit('resetStore')
    },
    setInputFile: ({ commit }, fileInfo) => {
      commit('updateInputFile', { ...fileInfo, fid: uuidV4() })
    },

    changeTransitionState: ({ commit, state }, options = {}) => {
      const type = getMimeTypeByExt(options.to)
      commit('updateOutputFile', {
        ...state.inputFile,
        name: `${state.inputFile.namePrefix}.${options.to}`,
        type,
        ext: options.to
      })
      commit('updateConvertStatus', CONVERT_STATUS.SUCCESS)
    },
    // 图片增强
    imageEnhanceFunction: async ({ commit, state, rootGetters, dispatch }, options = {}) => {
      commit('updateIsDownloading', true)
      // 上传文件
      let uploadResult = await uploadOSS(
        `${uuidV4()}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}_${state.inputFile.name}`,
        state.inputFile.raw,
        BUCKET
      )

      const source = uploadResult.url
      commit('updateInputFile', { ...state.inputFile, source })
      let molti = Number(options.molti)
      let res = {}
      if (options.method === METHODS.IMAGE_ENHANCE) {
        res = await imageEnhance(uploadResult.url, molti) // 图片增强，获取到那个2 4 6 8的倍数值
      }

      let imgUrl = ''
      if (res.data?.status === 0) {
        imgUrl = res.data?.data
        const imageUrl = imgUrl

        fetch(imageUrl)
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response.blob()
          })
          .then(blob => {
            // 判断图片类型
            const imageType = blob.type

            // 创建Blob URL
            const imageUrl = window.URL.createObjectURL(blob)

            // 创建一个下载链接
            const a = document.createElement('a')
            a.href = imageUrl
            a.download = `downloaded_image.${getImageExtension(imageType)}` // 使用 getImageExtension 获取文件扩展名
            a.style.display = 'none'
            document.body.appendChild(a)

            // 触发下载
            a.click()

            // 移除下载链接
            document.body.removeChild(a)

            // 释放Blob URL
            window.URL.revokeObjectURL(imageUrl)
          })
          .catch(error => {
            console.error('下载失败:', error)
          })

        function getImageExtension(imageType) {
          // 根据图片类型返回相应的文件扩展名
          switch (imageType) {
            case 'image/jpg':
              return 'jpg'
            case 'image/jpeg':
              return 'jpg'
            case 'image/png':
              return 'png'
            case 'image/gif':
              return 'gif'
            // 添加其他支持的图片类型
            default:
              return 'unknown'
          }
        }
        let downloadCounts = 1 // 默认下载次数
        let r = await duce(downloadCounts)
        if (r.data.status === 0) {
          await dispatch('updateAllCert', r.data.data, { root: true })
          // 扣除用户下载次数，然后将此次数据添加到下载历史
          commit('updateDownloadHistory', { ...state.outputFile, source: source })
          // const zipBlob = await zip.generateAsync({ type: 'blob' })
          // saveAs(zipBlob, '轻秒图片转换器.zip')
        } else {
          Message.error('下载失败！')
        }
        commit('updateDownloadStatus', true)
      } else {
        commit('updateDownloadStatus', false)
        Message.error('文件转换失败，请检查文件！')
      }
    },
    // 图片预览
    imagepreview: async ({ commit, state, rootGetters, dispatch }, options = {}) => {
      commit('updateIsDownloading', true)
      // 上传文件
      let uploadResult = await uploadOSS(
        `${uuidV4()}_${new Date().getTime()}_${Math.floor(Math.random() * 1000)}_${state.inputFile.name}`,
        state.inputFile.raw,
        BUCKET
      )

      const source = uploadResult.url
      commit('updateInputFile', { ...state.inputFile, source })
      let molti = Number(options.molti)
      let res = {}
      if (options.method === METHODS.IMAGE_ENHANCE) {
        res = await imageEnhance(uploadResult.url, molti) // 图片增强，获取到那个2 4 6 8的倍数值
        if (res.data.status != 0) {
          Message.error('系统繁忙，请稍后再试！')
          return
        }
        localStorage.setItem('imgUrl', res.data.data)
      }
    },
    // 下载
    async downloadFile({ state, dispatch, commit, rootState, rootGetters }, options) {
      // 判断是否有可以下载的图片
      if (state.convertStatus === CONVERT_STATUS.FAIL || isEmpty(state.outputFile)) {
        return
      }

      if (!isEmpty(state.downloadHistory)) {
        const zip = new JSZip()
        const fetchPromises = []
        // 下载文件
        if (Array.isArray(state.downloadHistory?.source)) {
          state.downloadHistory?.source.forEach((imgUrl, idx) => {
            let promise = fetch(imgUrl)
              .then(response => response.blob())
              .then(blob => {
                zip.file(`${state.outputFile.namePrefix}_idx.${state.outputFile.ext}`, blob, { binary: true })
              })
            fetchPromises.push(promise)
          })
          await Promise.all(fetchPromises) // 等待所有远程文件下载完成
        } else if (typeof state.downloadHistory?.source === 'string') {
          await fetch(state.downloadHistory?.source)
            .then(response => response.blob())
            .then(async blob => {
              zip.file(state.outputFile.name, blob, { binary: true })
            })
        }
        commit('updateDownloadStatus', true)
        commit('updateDownloadHistory', { ...state.outputFile, source: state.downloadHistory.source })
        const zipBlob = await zip.generateAsync({ type: 'blob' })
        saveAs(zipBlob, '轻秒图片转换器.zip')
      } else {
        // 执行转换
        dispatch('imageEnhance', options)
      }
    }
  }
}
