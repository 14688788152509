<template>
  <div class="userInfoWrapper" @mouseenter="handleMouseEnter">
    <img src="@/assets/img/user_avatar.png" alt="" class="avatar" />
    <div class="userInfoContainer">
      <div class="userInfoBox">
        <img src="@/assets/img/user_avatar.png" alt="" class="userAvatar" />
        <div class="userInfoBoxRight">
          <div class="nickname">{{ '轻秒' + userInfo.uid }}</div>
          <div class="user">
            <span class="tag" v-if="allCert?.vip === VIP_LEVEL.NON_VIP">免费用户</span>
            <span class="vipTag" v-if="isTimeVIP || isCfVIP">VIP会员</span>
            <div class="userIdBox">
              <span class="userId">ID: {{ userInfo.uid }}</span>
              <span class="copyBtn" :data-clipboard-text="userInfo.uid"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="residualEquity">
        <div class="countBox">
          <span class="count">剩余次数：{{ counts }}</span>
          <span>{{ descTxt }}</span>
        </div>
        <PurchaseButton
          v-if="allCert?.vip !== VIP_LEVEL.PERMANENT_VIP"
          width="88px"
          height="32px"
          border-radius="28px"
          font-size="12px"
          >{{ btnTxt }}
        </PurchaseButton>
      </div>
      <a href="/account/setting" class="settingLink">账号设置</a>
      <LogoutButton>退出登录</LogoutButton>
    </div>
  </div>
</template>
<script>
import PurchaseButton from '@/components/PurchaseButton/index.vue'
import LogoutButton from '@/components/LogoutButton/index.vue'
import ClipboardJS from 'clipboard'
import { Message } from 'element-ui'
import { mapGetters, mapState } from 'vuex'
import { VIP_LEVEL, COMPRESS_UPLOAD_CONFIG } from '@/config'

export default {
  name: 'UserInfo',
  components: { LogoutButton, PurchaseButton },
  props: {},
  data() {
    return {
      clipboard: null,
      VIP_LEVEL,
      COMPRESS_UPLOAD_CONFIG
    }
  },
  methods: {
    async handleMouseEnter() {
      await this.$store.dispatch('updateUserInfo')
    }
  },

  computed: {
    ...mapGetters(['getUserInfo', 'isTimeVIP', 'isVip', 'isCfVIP']),
    ...mapState({
      allCert: state => state.allCert,
      userInfo: state => state.userInfo
    }),
    btnTxt() {
      if (!this.isVip) {
        return '开通会员'
      }
      return '续费会员'
    },
    descTxt() {
      if (this.isTimeVIP) {
        return `到期时间：${this.allCert?.vip_expiration_date}`
      } else if (this.allCert?.vip === VIP_LEVEL.PERMANENT_VIP) {
        return `到期时间：永久`
      } else {
        return '开通会员解锁全部功能'
      }
    },
    counts() {
      switch (this.allCert?.vip) {
        case VIP_LEVEL.COUNT_VIP:
          return this.allCert.has_image_count
        case VIP_LEVEL.NON_VIP:
          return 0
        default:
          return '无限次'
      }
    }
  },

  created() {},
  mounted() {
    this.clipboard = new ClipboardJS('.copyBtn')
    this.clipboard.on('success', function (e) {
      e.clearSelection()
      Message({
        type: 'success',
        message: '复制成功'
      })
    })

    this.clipboard.on('error', function () {
      Message({
        type: 'error',
        message: '复制失败'
      })
    })
  },
  destroyed() {
    typeof this.clipboard?.destroyed === 'function' && typeof this.clipboard?.destroyed()
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
