/**
 * @description 获取存储在本地的用户信息
 * @returns {*}
 */
export function getLocalUserInfo() {
  let data
  try {
    data = JSON.parse(localStorage.getItem('UID'))
  } catch (e) {
    data = void 0
  }
  return data
}
export function setLocalUserInfo(userInfo) {
  localStorage.setItem('UID', JSON.stringify(userInfo))
}

/**
 * @description 移除token
 */
export function removeToken() {
  localStorage.removeItem('SID')
  localStorage.removeItem('UID')
}
export function getToken() {
  return localStorage.getItem('SID')
}
/**
 * @description 设置本地token与用户信息
 * @param user
 */
export function setToken(user) {
  if (user) {
    let { token } = user
    localStorage.setItem('SID', token)
    if (Object.keys(user).includes('money_info')) {
      delete user.money_info
    }
    if (Object.keys(user).includes('privilege')) {
      delete user.privilege
    }
    setLocalUserInfo(user)
  }

  // if (getLocalUserInfo()) {
  //   cookie.set('UID', getLocalUserInfo(), {
  //     expires: 7
  //   })
  // }
}
