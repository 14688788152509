import Vue from 'vue'
import Main from './main.vue'

let BindWechatConstructor = Vue.extend(Main)

const BindWechat = options => {
  options = options || {}
  let data = Object.assign({}, options, {
    afterClose: vm => {
      if (typeof options?.afterClose === 'function') {
        options.afterClose()
      }
      // vm.$destroy()
    }
  })
  let instance = new BindWechatConstructor({
    data: data
  })
  instance.$mount()
  document.body.appendChild(instance.$el)
  instance.visible = true
  return instance
}

export default BindWechat
