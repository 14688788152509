<template>
  <el-dialog
    class="messageDialogBox"
    :visible.sync="visible"
    width="400px"
    :append-to-body="true"
    :show-close="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :lock-scroll="false"
    :custom-class="'messageDialogContainer'"
    :destroy-on-close="true"
  >
    <div class="bindDialog">
      <div class="titleBox">
        <span class="title">绑定订单号</span>
        <i class="el-icon-close closeIcon" @click="handleClose"></i>
      </div>
      <div class="content">
        <div class="contentText">绑定后可使用微信扫码登录</div>
        <el-form ref="form">
          <el-form-item prop="orderId">
            <el-input v-model="form.orderId" autocomplete="off" placeholder="请输入交易订单号(长)"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="btnGroups">
        <button class="rightBtn btn" @click="onClickBind">绑定</button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { Message } from 'element-ui'
import { bindOrder } from '@/api'

export default {
  name: 'BindOrder',
  data() {
    return {
      title: '温馨提示',
      visible: false,
      onClose() {},
      afterClose() {},
      form: {
        orderId: ''
      }
    }
  },
  methods: {
    hide() {
      this.visible = false
      if (typeof this.afterClose === 'function') {
        this.afterClose(this)
      }
    },
    handleClose() {
      this.hide()
      if (typeof this.onClose === 'function') {
        this.onClose(this)
      }
    },
    checkOrderId() {
      if (this.form.orderId.trim().length === 0) {
        Message.error('请输入订单号！')
        return false
      }
      return true
    },
    async onClickBind() {
      if (this.checkOrderId()) {
        let res = await bindOrder(this.form.orderId)
        if (res.data.status === 0) {
          Message.success('绑定成功！')
          await this.$store.dispatch('updateUserInfo')
        } else if (res.data.status === 400) {
          Message.error('订单号错误！')
        } else if (res.data.status === 2005) {
          Message.error('该订单号已被使用！')
        } else if (res.data.status === 2002) {
          Message.error('该账号已是VIP！')
        } else if (res.data.status === 2001) {
          Message.error('请退出当前游客登录，使用微信扫码登录再进行绑定操作！')
        } else if (res.data.status === 2006) {
          Message.error('订单号错误！')
        } else {
          Message.error('绑定失败！')
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
@import url('./main.less');
</style>
